import React from 'react'
import styles from './entry.module.css'
import { useTranslation } from 'react-i18next'
import Navbar from '../../molecules/navbar/NavbarView'

const SearchNotfoundScreen = () => {
  const { t } = useTranslation(['tracking'])

  return (
    <div className={'container'}>
      <Navbar
        title={t('search.title')}
        left={'뒤로'}
        classname={'navbar-search'}
        titleClassname={'navbar-title-search'}
      />
      <div className={'inner-container column'}>
        <div className={styles.searchInner}>
          <div className={'title bold black85'}>{t('search.res.title')}</div>
          <div className={styles.roundCntr + ' ' + styles.roundInner}>
            <div className={'subtitle bold primary preline'}>{t('search.notfound.label1')}</div>
            <div className={'small medium black45'}>{t('search.notfound.label2')}</div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SearchNotfoundScreen
