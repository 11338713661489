import React, { useEffect, useState } from 'react'
import './tracking.style.css'
import { useTranslation } from 'react-i18next'
import Navbar from '../../molecules/navbar/NavbarView'
import { Dropdown, Menu } from 'antd'
import { useHistory } from 'react-router-dom'
import TrackingComponent from './TrackingComponent'
import EntranceInfoRegister from '../entranceInfo/EntranceInfoRegister'
import ArrowDown from '../../../assets/svgs/ArrowDown'
import { EMenu } from './type'
import PickupLocationRegister from '../pickupLocation/PickupLocationRegister'
import { ExchangePlace } from '../placeExchange/ExchangePlace'
import { ReturnPlace } from '../placeReturn/ReturnPlace'
import { ReturnRequestScreen } from '../reqReturn/ReturnRequestScreen'
import { useDeliveryInfo } from '../../../api/useDeliveryInfo'
import { useShippingInfo } from '../../../api/useShippingInfo'
import { showMessageWithTitle } from '../../../fns/message'

interface Params {
  params: {
    tracking: string
    opt?: EMenu
  }
}
const TrackingScreen = ({ match }: { match: Params }) => {
  const { tracking, opt } = match.params
  const { t } = useTranslation(['tracking'])
  const [option, setOption] = useState<EMenu>(EMenu.tracking)
  const history = useHistory()
  const [visible, setVisible] = useState(false)
  const { shipping, mutate: mutateInfo } = useShippingInfo(tracking)
  const { deliveryInfo, mutate } = useDeliveryInfo(tracking)

  useEffect(() => {
    document.body.scrollTop = 0;
  }, [option])

  useEffect(() => {
    if (opt && opt in EMenu && (!(opt === EMenu.reqReturn) || shipping?.allow_return)) {
      setOption(opt)
    }
  }, [opt])

  const handleChangeOption = ({ key }: any) => {
    if (key === 'search') {
      history.push('/')
    } else {
      setOption(key)
      handleVisibleChange(false)
    }
  }

  const handleVisibleChange = (flag: boolean) => {
    setVisible(flag)
  }

  const handleEntrySaved = () => {
    setOption(EMenu.tracking)
    mutate()
    mutateInfo()
  }

  const handleReturnRequested = () => {
    setOption(EMenu.return)
    mutate()
    mutateInfo()
  }

  const handleReturnPlaceSaved = () => {
    if (shipping?.tracking_number_return) {
      history.push(`/tracking/${shipping?.tracking_number_return}/tracking`);
      window.location.reload();
    }
    else
      setOption(EMenu.tracking)
    mutate()
    mutateInfo()
  }

  const handleGoReturn = () => {
    if (!shipping?.tracking_number_return) {
      setOption(EMenu.reqReturn)
    } else {
      showMessageWithTitle(t('Notify'), t('There is a return in progress'))
    }
  }

  const showVisible = () => {
    setVisible(true)
  }

  const menuComponent = (
    <Menu
      inlineIndent={0}
      className={'option-menu'}
      defaultSelectedKeys={[EMenu.search]}
      selectedKeys={[option]}
      expandIcon={<ArrowDown color={'var(--black45)'} rotate={true ? 180 : 0} />}
      defaultOpenKeys={['shipping']}
      onClick={handleChangeOption}
    >
      <Menu.Item key={EMenu.search} className={'option-item'}>
        {t('search.title')}
      </Menu.Item>
      <Menu.Item key={EMenu.tracking} className={'option-item'}>
        {t('tracking.title')}
      </Menu.Item>
      <Menu.Item key={EMenu.info} className={'option-item'}>
        {t('info.title')}
      </Menu.Item>
      <Menu.Item key={EMenu.entrance} className={'option-item'}>
        {t('entrance.title')}
      </Menu.Item>
      {!shipping?.tracking_number_return &&
        shipping?.allow_return &&
        !shipping?.is_return &&
        shipping?.status !== 'CREATED' &&
        shipping?.status !== 'READYFORPICKUP' && (
          <Menu.Item key={EMenu.reqReturn} className={'option-item'}>
            {t('Request return')}
          </Menu.Item>
        )}
      {shipping?.is_exchange && (
        <Menu.Item key={EMenu.exchange} className={'option-item'}>
          <span className={'body black85'}>{t('exchange.title')}</span>
        </Menu.Item>
      )}
      {shipping?.is_return && (
        <Menu.Item key={EMenu.return} className={'option-item'}>
          {t('return.title')}
        </Menu.Item>
      )}
    </Menu>
  )

  const renderComponent = () => {
    switch (option) {
      case EMenu.info:
        return (
          <PickupLocationRegister
            shipping={shipping}
            info={deliveryInfo}
            tracking={tracking}
            onEntrySaved={handleEntrySaved}
          />
        )
      case EMenu.tracking:
        return <TrackingComponent tracking={tracking} shippingInfo={shipping} onReturn={handleGoReturn} />
      case EMenu.exchange:
        return <ExchangePlace info={deliveryInfo} tracking={shipping?.tracking_number_return || tracking} onEntrySaved={handleEntrySaved} />
      case EMenu.return:
        return <ReturnPlace info={deliveryInfo} tracking={shipping?.tracking_number_return || tracking} onEntrySaved={handleReturnPlaceSaved} />
      case EMenu.reqReturn:
        return <ReturnRequestScreen info={deliveryInfo} tracking={shipping?.tracking_number_return || tracking} onComplete={handleReturnRequested} />
      case EMenu.entrance:
        return <EntranceInfoRegister tracking={tracking} onEntrySaved={handleEntrySaved} />
    }
  }

  return (
    <div className={'container'}>
      <Navbar
        title={t(option + '.title')}
        left={''}
        classname={'navbar-' + option}
        titleClassname={'navbar-title-' + option}
      />
      <div data-cy={'dropDownDiv'} className={'inner-container column'}>
        <Dropdown
          overlay={menuComponent}
          align={{ offset: [0, 0] }}
          placement={'bottom'}
          onVisibleChange={handleVisibleChange}
          visible={visible}
          trigger={['click']}
        >
          <div className={'option-cntr row space-between'}>
            <div
              onMouseEnter={e => e.preventDefault()}
              className={'primary body bold'}
              onChange={showVisible}
              data-cy={'dropDown'}
            >
              {t(option + '.title')}
            </div>
            <ArrowDown color={'#00000045'} rotate={0} />
          </div>
        </Dropdown>
        {renderComponent()}
      </div>
    </div>
  )
}

export default TrackingScreen
