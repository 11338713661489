import React, { useEffect, useState } from 'react'
import styles from './request.module.css'
import { useTranslation } from 'react-i18next'
import { Select } from 'antd'
import ShippingPhoto from '../../molecules/photo/ShippingPhoto'
import sizes from '../../../datas/box.sizes.json'
import { EBoxSize } from '../../screens/tracking/type'
import { GetDeliveryInfoResponse } from '../../../api/apiTypes'
import { InputNumber } from '../../molecules/inputNumber/InputNumber'

const { Option } = Select

type Props = {
  info?: GetDeliveryInfoResponse
  onCountChanged: (count: number) => void
  onSizeSelected: (size: EBoxSize) => void
  onSelectPhoto: (file: File) => void
}
const RequestForm = ({ info, onCountChanged, onSizeSelected, onSelectPhoto }: Props) => {
  const { t } = useTranslation(['location'])
  const [photo, setPhoto] = useState(info?.photo)
  const [size, setSize] = useState<EBoxSize>()

  useEffect(() => {
    if (info) {
      if (info.photo) {
        setPhoto(info?.photo)
      }
      if (info.size && info.size in EBoxSize) {
        setSize(info?.size)
      }
    }
  }, [info])

  useEffect(() => {
    size && onSizeSelected(size)
  }, [size])

  const photoNode = (
    <div className={'column'}>
      <div className={styles.radioGrTitle}>
        <span className={'body2 bold black85'}>{t('photo.pickup.location')}</span>
        <span className={'small bold black45'}>{t('select')}</span>
      </div>
      <ShippingPhoto photo={photo} onSelect={onSelectPhoto} />
    </div>
  )

  const sizeSelecter = (
    <Select
      size={'large'}
      className={styles.selector}
      dropdownStyle={{
        borderRadius: 7,
      }}
      value={size}
      placeholder={t('post.box.standard')}
      onChange={size => setSize(size)}
    >
      <Option disabled className={'column ' + styles.option}>
        <span className={'body black45'}>{t('post.box.standard')}</span>
      </Option>
      {sizes.map((size: string) => (
        <Option key={'sizes' + size} value={size} className={'column ' + styles.option}>
          <span className={'body black85'}>{t(size)}</span>
        </Option>
      ))}
    </Select>
  )

  const methodNode = (
    <div className={'column ' + styles.methodCntr}>
      <div className={styles.radioGrTitle}>
        <span className={'body2 bold black85'}>{t('product.info')}</span>
        <span className={'small bold black45'}>{t('select')}</span>
      </div>
      <div className={styles.roundCntr}>
        <div className={'row space-between ' + styles.trackingOption}>
          <span data-cy={'delivery'} className={'body2 bold '}>
            {t('box.size')}
          </span>
          {sizeSelecter}
        </div>
        <div className={'row space-between ' + styles.trackingOption}>
          <span data-cy={'delivery'} className={' body2 bold '}>
            {t('box.count')}
          </span>
          <InputNumber
            className={styles.input}
            min={0}
            max={99}
            value={info?.package_count}
            onChangeCount={onCountChanged}
          />
        </div>
      </div>
    </div>
  )

  return (
    <>
      {photoNode}
      {methodNode}
    </>
  )
}

export default RequestForm
