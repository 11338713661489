import React, { useRef } from 'react'
import styles from './return.module.css'
import { useTranslation } from 'react-i18next'
import { Button, Space } from 'antd'
import { putDeliveryComplete } from '../../../api/shippingApi'
import { showMessage, showMessageWithTitle } from '../../../fns/message'
import { entryPasswordRegex, passwordRegex } from '../../../common/regex'
import EntranceForm from '../../organism/entrance/EntranceForm'
import { EBoxSize, ECompleteMethod, EEntranceOption } from '../tracking/type'
import PickupLocationForm from '../../organism/pickupLocation/PickupLocationForm'
import RequestForm from '../../organism/request/RequestForm'
import { GetDeliveryInfoResponse } from '../../../api/apiTypes'

export const ReturnPlace = ({
  info,
  tracking,
  onEntrySaved,
}: {
  info?: GetDeliveryInfoResponse
  tracking: string
  onEntrySaved: () => void
}) => {
  const { t } = useTranslation(['location'])
  const boxRef = useRef('')
  const boxPassRef = useRef('')
  const completeInfo = useRef('')
  const entranceCodeRef = useRef('')
  const entranceRef = useRef<EEntranceOption>()
  const completeMethodRef = useRef<ECompleteMethod>(ECompleteMethod.DOOR)
  const photoRef = useRef<File>()
  const boxSizeRef = useRef<EBoxSize>()
  const boxCountRef = useRef<number>()

  const showSuccess = () => {
    showMessageWithTitle(
      t('popup.success.entry.title.return'),
      t('popup.success.entry.content.return'),
      t('popup.success.entry.btn.ok'),
      onEntrySaved
    )
  }

  const handleSaveResult = () => {
    if (entranceRef.current === EEntranceOption.GATECODE) {
      //공동현관 비밀번호 선택시
      if (!entranceCodeRef.current) {
        showMessage(t('popup.error.entry.code.empty'))
        return
      } else if (!!entranceCodeRef.current && entryPasswordRegex.test(entranceCodeRef.current)) {
        showMessage(t('popup.error.code.invalid'))
        return
      }
    }
    if (completeMethodRef.current === ECompleteMethod.DELIVERYBOX) {
      //택배함 선택시
      if (!!boxPassRef.current && !passwordRegex.test(boxPassRef.current)) {
        showMessage(t('popup.error.code.invalid'))
        return
      }
    } else if (completeMethodRef.current === ECompleteMethod.OTHER) {
      if (!completeInfo.current) {
        showMessage(t('popup.error.other.empty'))
        return
      }
    }
    putDeliveryComplete(
      tracking,
      {
        entrance_option: entranceRef.current,
        entrance_password: entranceCodeRef.current,
        photo: photoRef.current,
        delivery_box_number: boxRef.current,
        delivery_box_password: boxPassRef.current,
        delivery_complete_on: completeMethodRef.current,
        delivery_complete_on_info: completeInfo.current,
        size: boxSizeRef.current,
        package_count: boxCountRef.current,
      },
      showSuccess
    )
  }

  return (
    <Space direction={'vertical'} size={20} className={styles.inner}>
      <div className={styles.roundCntr + ' ' + styles.infoContainer}>
        <div className={'h2 primary preline'}>{t('pickup.location.info2')}</div>
      </div>
      {tracking && (
        <div className={styles.roundCntr + ' ' + styles.trackingContainer}>
          <div className={'body2 preline'}>{`${t('Return tracking No')}: ${tracking}`}</div>
        </div>
      )}
      <RequestForm
        info={info}
        onCountChanged={count => (boxCountRef.current = count)}
        onSizeSelected={size => (boxSizeRef.current = size)}
        onSelectPhoto={photo => (photoRef.current = photo)}
      />
      <PickupLocationForm
        info={info}
        title={t('loc.return.title')}
        onBoxNumChange={boxNum => (boxRef.current = boxNum)}
        onBoxPasswordChange={pass => (boxPassRef.current = pass)}
        onCompleteMethodChange={method => (completeMethodRef.current = method)}
        onCompleteInfoChange={info => (completeInfo.current = info)}
      />
      <EntranceForm
        info={info}
        onEntrancePasswordChange={pass => (entranceCodeRef.current = pass)}
        onEntranceOptionChange={option => (entranceRef.current = option)}
      />
      <div style={{ height: 100 }} />
      <Button type={'primary'} className={styles.btnSave} data-cy={'saveResult'} onClick={handleSaveResult}>
        {t('btn.return.title')}
      </Button>
    </Space>
  )
}
