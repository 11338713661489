import React, { useMemo, useState } from 'react'
import './shipping.css'
import { useTranslation } from 'react-i18next'
import Button from 'antd/lib/button'
import camera from '../../../assets/svgs/camera.svg'
import UploadBtn from './UploadBtn'
import Compressor from 'compressorjs'
import { showApiError } from '../../../fns/message'
import DeliveryHistoryPhotos from './DeliveryHistoryPhotos'

type Props = {
  photo?: string
  onSelect: (image: File) => void
  disabled?: boolean
  className?: string
}
const ShippingPhoto = ({ photo, disabled, onSelect, className }: Props) => {
  const [image, setImage] = useState<File>()
  const [visiblePhoto, setVisiblePhoto] = useState(false)
  const previewImage = useMemo(() => (image ? URL.createObjectURL(image) : photo), [image, photo])

  const { t } = useTranslation(['delivery'])

  const showPhoto = () => {
    setVisiblePhoto(true)
  }

  const hidePhoto = () => {
    setVisiblePhoto(false)
  }

  const handleUpload = (file: File) => {
    if (file?.size >= 1000000) {
      let quality = 0.1
      if (file.size < 10000000) {
        quality = 1 - file.size / 10000000
      }
      console.log('file quality', quality)
      //1Gb
      new Compressor(file, {
        quality,
        convertSize: 1000000,
        // The compression process is asynchronous,
        // which means you have to access the `result` in the `success` hook function.
        success(blob) {
          const file: File = blob as File
          setImage(file)
          onSelect(file)
        },
        error: showApiError,
      })
    } else {
      setImage(file)
      onSelect(file)
    }
  }

  return (
    <div className={'row ' + className}>
      <UploadBtn className={'delivery-complete-image'} onUpload={handleUpload}>
        <img alt={'camera'} src={camera} className={'delivery-camera'} />
      </UploadBtn>
      {previewImage && (
        <Button className={'delivery-complete-image'} onClick={showPhoto}>
          <img
            src={previewImage}
            className={disabled ? 'delivery-preview delivery-preview-disabled' : 'delivery-preview'}
          />
        </Button>
      )}
      <DeliveryHistoryPhotos visible={visiblePhoto} onBack={hidePhoto} previewImage={previewImage} />
    </div>
  )
}

export default ShippingPhoto
