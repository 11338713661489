export enum EEntranceOption {
  FREE = 'FREE',
  GATECODE = 'GATECODE',
  GUARD = 'GUARD',
}

export enum EReturnReason {
  DAMAGED = 'DAMAGED',
  WRONG_TIEM = 'WRONG_TIEM',
  NEED_EXCHANGE = 'NEED_EXCHANGE',
  NO_REASON = 'NO_REASON',
  QUALITY = 'QUALITY',
  MANUAL = 'MANUAL',
}

export enum ECompleteMethod {
  'DOOR' = 'DOOR',
  'DELIVERYBOX' = 'DELIVERYBOX',
  'GUARD' = 'GUARD',
  'OTHER' = 'OTHER',
}

export enum EBoxSize {
  'NO.1' = 'NO.1',
  'NO.2' = 'NO.2',
  'NO.3' = 'NO.3',
  'NO.4' = 'NO.4',
  'NO.5' = 'NO.5',
  'NO.6' = 'NO.6',
}

export enum EMenu {
  search = 'search',
  info = 'info',
  entrance = 'entrance',
  reqReturn = 'reqReturn',
  return = 'return',
  exchange = 'exchange',
  tracking = 'tracking',
}

export interface IEntranceChangeParams {
  entrance_option?: EEntranceOption
  entrance_password?: string
  photo?: File
  save?: boolean
  package_count?: number
  size?: EBoxSize
  delivery_box_number?: string
  delivery_box_password?: string
  delivery_complete_on?: ECompleteMethod
  delivery_complete_on_info?: string
}

export type TRequestReturnOrder = {
  order_item_number: string
  product_name: string
  quantity: number
}
export interface IRequestReturnParams {
  return_reason?: EReturnReason
  return_memo?: string
  process_return?: boolean
  order_items?: TRequestReturnOrder[]
}

export interface IEntranceCodeChangeParams {
  entrance_option?: EEntranceOption
  information?: string
  address_extra?: string
}
