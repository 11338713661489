import React, { useEffect, useState } from 'react'
import styles from './pickup.module.css'
import { useTranslation } from 'react-i18next'
import { Input, Radio } from 'antd'
import { ECompleteMethod } from '../../screens/tracking/type'
import { GetDeliveryInfoResponse } from '../../../api/apiTypes'

type Props = {
  info?: GetDeliveryInfoResponse
  title?: string
  onCompleteMethodChange: (entrancePassword: ECompleteMethod) => void
  onCompleteInfoChange: (entrancePassword: string) => void
  onBoxNumChange: (number: string) => void
  onBoxPasswordChange: (password: string) => void
}
const PickupLocationForm = ({
  info,
  title,
  onCompleteInfoChange,
  onCompleteMethodChange,
  onBoxPasswordChange,
  onBoxNumChange,
}: Props) => {
  const { t } = useTranslation(['location'])
  const [method, setMethod] = useState(ECompleteMethod.DOOR)
  const [completeInfo, setCompleteInfo] = useState(info?.delivery_complete_on_info)
  const [boxNum, setBoxNum] = useState(info?.delivery_box_number)
  const [boxPass, setBoxPass] = useState(info?.delivery_box_password)

  useEffect(() => {
    if (info) {
      if (info.delivery_complete_on && info.delivery_complete_on in ECompleteMethod) {
        setMethod(info?.delivery_complete_on)
      }
      if (info.delivery_complete_on_info) {
        setCompleteInfo(info?.delivery_complete_on_info)
      }
      if (info.delivery_box_number) {
        setBoxNum(info?.delivery_box_number)
      }
      if (info.delivery_box_password) {
        setBoxPass(info?.delivery_box_password)
      }
    }
  }, [info])

  useEffect(() => {
    onCompleteMethodChange(method)
  }, [method])

  useEffect(() => {
    completeInfo && onCompleteInfoChange(completeInfo)
  }, [completeInfo])

  useEffect(() => {
    boxPass && onBoxPasswordChange(boxPass)
  }, [boxPass])

  useEffect(() => {
    boxNum && onBoxNumChange(boxNum)
  }, [boxNum])

  return (
    <div className={'column'}>
      <Radio checked={!!method} className={'body2 bold ' + styles.radioGrTitle}>
        {title || t('info.title')}
      </Radio>
      <Radio.Group
        className={styles.roundCntr + ' column ' + styles.radioGrp}
        onChange={e => setMethod(e.target.value)}
        value={method}
      >
        <Radio data-cy={'door'} value={'DOOR'} className={'body2 bold ' + styles.trackingOption}>
          {t('DOOR')}
        </Radio>
        <div className={styles.trackingOption}>
          <div className={styles.radioCol}>
            <Radio data-cy={'delivery'} value={'DELIVERYBOX'} className={'body2 bold '}>
              {t('DELIVERYBOX')}
            </Radio>
            {method === 'DELIVERYBOX' && (
              <div className={'row'}>
                <Input
                  inputMode="tel"
                  maxLength={20}
                  defaultValue={''}
                  value={boxNum}
                  autoComplete={'off'}
                  data-cy={'deliveryChange'}
                  className={styles.input + ' ' + styles.inputCode}
                  placeholder={t('loc.number.place')}
                  onChange={e => setBoxNum(e.target.value)}
                />
                <Input
                  inputMode="tel"
                  defaultValue={''}
                  maxLength={20}
                  value={boxPass}
                  autoComplete={'off'}
                  data-cy={'boxChange'}
                  className={styles.input + ' ' + styles.inputPass}
                  placeholder={t('loc.input.box.place')}
                  onChange={e => setBoxPass(e.target.value)}
                />
              </div>
            )}
          </div>
        </div>
        <Radio data-cy={'guard'} value={'GUARD'} className={'body2 bold ' + styles.trackingOption}>
          {t('GUARD')}
        </Radio>

        <div className={styles.trackingOption}>
          <div className={styles.radioCol}>
            <div className={'row'}>
              <Radio data-cy={'other'} value={'OTHER'} style={{ margin: 0 }} className={'body2 bold '}>
                {t('OTHER')}
              </Radio>
              {method === 'OTHER' && <div className={styles.otherInfo}>{t('loc.other.info')}</div>}
            </div>
            {method === 'OTHER' && (
              <Input
                value={completeInfo}
                autoComplete={'off'}
                data-cy={'otherChange'}
                className={styles.input}
                placeholder={t('loc.other.place')}
                onChange={e => setCompleteInfo(e.target.value)}
              />
            )}
          </div>
        </div>
      </Radio.Group>
    </div>
  )
}

export default PickupLocationForm
