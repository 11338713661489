import React, { useRef, useState } from 'react'
import styles from './entry.module.css'
import { useTranslation } from 'react-i18next'
import { Button, Input, Space } from 'antd'
import { showMessage } from '../../../fns/message'
import Navbar from '../../molecules/navbar/NavbarView'
import { useHistory } from 'react-router-dom'
import PopupSpinner from '../../molecules/spinner/PopupSpinnerView'

const SearchDeliveryScreen = () => {
  const history = useHistory()
  const { t } = useTranslation(['tracking'])
  const trackingRef = useRef('')
  const [loading, setLoading] = useState(false)

  const handleChangeDelivery = (e: any | { target: { value: string } }) => {
    trackingRef.current = e.target.value
  }

  const handleSearch = () => {
    //공동현관 비밀번호 선택시
    if (!trackingRef.current) {
      showMessage(t('popup.error.tracking.empty'))
      return
    }
    setLoading(true)
    history.push(`/tracking/${trackingRef.current}`)
  }

  return (
    <div className={'container'}>
      <Navbar title={t('search.title')} left={''} classname={'navbar-search'} titleClassname={'navbar-title-search'} />
      <div className={'inner-container column'}>
        <Space direction={'vertical'} size={10} className={styles.searchInner}>
          <div className={'column'}>
            <div className={'title bold black85'}>{t('search.subtitle')}</div>
            <div className={'body black45'}>{t('search.label')}</div>
          </div>
          <Input
            maxLength={24}
            defaultValue={''}
            autoComplete={'off'}
            autoFocus
            className={styles.inputSearch + ' body1 bold'}
            placeholder={t('search.input.place')}
            data-cy={'deliveryID'}
            onChange={handleChangeDelivery}
          />
          <Button type={'primary'} data-cy={'submitID'} onClick={handleSearch}>
            {t('search.btn')}
          </Button>
        </Space>
      </div>
      {loading && <PopupSpinner />}
    </div>
  )
}

export default SearchDeliveryScreen
