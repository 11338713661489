import React from 'react'
import './spinner.css'
import Lottie from 'react-lottie'
import spinner from '../../../assets/lotties/spinner.json'

const PopupSpinner = () => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: spinner,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  }
  return (
    <div className={'spinner-container'}>
      <Lottie options={defaultOptions} height={61} width={54} />
    </div>
  )
}

export default PopupSpinner
