import React from 'react'
import './App.css'
import 'swiper/css'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import SearchDeliveryScreen from './components/screens/search/SearchDeliveryScreen'
import SearchNotfoundScreen from './components/screens/search/SearchNotfoundScreen'
import { getLCP, getFID, getCLS } from 'web-vitals'
import TrackingScreen from './components/screens/tracking/TrackingScreen'
import NotfoundScreen from './components/screens/notfound/NotfoundScreen'

declare global {
  interface Window {
    Cypress: any
    initialState: any
    install: any
    installed: boolean
  }
}

if (process.env.NODE_ENV === 'production') {
  console.log = function no_console() {}
}

function App() {
  getCLS(console.log)
  getFID(console.log)
  getLCP(console.log)
  const appHeight = () => {
    const doc = document.documentElement
    doc.style.setProperty('--app-height', `${window.innerHeight}px`)
  }

  window.addEventListener('resize', appHeight)
  appHeight()

  return (
    <Router>
      <Switch>
        <Route exact path="/tracking/:tracking/" component={TrackingScreen} />
        <Route path={'/tracking/:tracking/:opt'} component={TrackingScreen} />
        <Route exact path="/notfound" component={SearchNotfoundScreen} />
        <Route exact path="/" component={SearchDeliveryScreen} />
        <Route path="*" component={NotfoundScreen} />
      </Switch>
    </Router>
  )
}

export default App
