import React, { ChangeEvent, useEffect, useState } from 'react'
import styles from './input.module.css'
import { Input } from 'antd'

interface Props {
  max?: number
  min?: number
  value?: number
  className?: string
  onChangeCount?: (count: number) => void
}

export const InputNumber = ({ max = 1000, min = 0, value, onChangeCount, className }: Props) => {
  const [count, setCount] = useState(value || 1)

  const handleChangeDown = () => {
    console.log('handleChangeDown', count)
    setCount(count => {
      if (min < count) {
        count = count - 1
        onChangeCount && onChangeCount(count)
      }
      return count
    })
  }

  const handleChangeUp = () => {
    console.log('handleChangeUp', count)
    setCount(count => {
      if (max > count) {
        count = count + 1
        onChangeCount && onChangeCount(count)
      }
      return count
    })
  }

  const handleChangeCount = (e: ChangeEvent<HTMLInputElement>) => {
    let count = Number(e.target.value)
    console.log('handleChangeCount', count)
    if (count >= min && max >= count) {
      setCount(count)
      onChangeCount && onChangeCount(count)
    } else {
      setCount(prev => prev)
    }
  }

  return (
    <Input
      addonBefore={
        <span onClick={handleChangeDown} className={styles.inputDown}>
          -
        </span>
      }
      addonAfter={
        <span onClick={handleChangeUp} className={styles.inputUp}>
          +
        </span>
      }
      maxLength={2}
      className={styles.input + ' ' + className}
      value={count}
      onChange={handleChangeCount}
    />
  )
}
