import React, { useRef } from 'react'
import styles from './entranceScreen.module.css'
import { useTranslation } from 'react-i18next'
import { Button, Space } from 'antd'
import { postEntrance } from '../../../api/shippingApi'
import { showMessage, showMessageWithTitle } from '../../../fns/message'
import { entryPasswordRegex } from '../../../common/regex'
import EntranceForm from '../../organism/entrance/EntranceForm'
import { EEntranceOption } from '../tracking/type'
import { GetDeliveryInfoResponse, GetShippingInfoResponse } from '../../../api/apiTypes'

/**
 * 공동현관 비밀번호를 수령정보랑 다른 entity입니다. 공동현관은 이전 데이터를 보여 주지 않는다. by kokospapa
 * @param tracking
 * @param onEntrySaved
 * @constructor
 */
const EntranceInfoRegister = ({ tracking, onEntrySaved }: { tracking: string; onEntrySaved: () => void }) => {
  const { t } = useTranslation(['location'])
  const entranceCodeRef = useRef('')
  const entranceRef = useRef<EEntranceOption>()

  const showSuccess = () => {
    showMessageWithTitle(
      t('popup.success.location.title'),
      t('popup.success.location.content'),
      t('popup.success.entry.btn.ok')
    )
    onEntrySaved()
  }

  const handleChangeEntrance = (value: EEntranceOption) => {
    entranceRef.current = value
  }
  const handleChangeEntranceCode = (value: string) => {
    entranceCodeRef.current = value
  }

  const handleSaveResult = () => {
    if (entranceRef.current === EEntranceOption.GATECODE) {
      //공동현관 비밀번호 선택시
      if (!entranceCodeRef.current) {
        showMessage(t('popup.error.entry.code.empty'))
        return
      } else if (!!entranceCodeRef.current && entryPasswordRegex.test(entranceCodeRef.current)) {
        showMessage(t('popup.error.code.invalid'))
        return
      }
    }
    postEntrance(
      tracking,
      {
        entrance_option: entranceRef.current,
        information: entranceCodeRef.current,
      },
      showSuccess
    )
  }

  return (
    <Space direction={'vertical'} size={20} className={styles.inner}>
      <div className={styles.roundCntr + ' ' + styles.infoContainer}>
        <div className={'h2 primary preline'}>{t('entrance.large.label')}</div>
        <div className={'body black45 preline ' + styles.label}>{t('entrance.label')}</div>
      </div>
      <EntranceForm onEntrancePasswordChange={handleChangeEntranceCode} onEntranceOptionChange={handleChangeEntrance} />
      <div style={{ height: 100 }} />
      <Button type={'primary'} className={styles.btnSave} data-cy={'saveResult'} onClick={handleSaveResult}>
        {t('complete')}
      </Button>
    </Space>
  )
}

export default EntranceInfoRegister
