import React, { Suspense } from 'react'
import ReactDOM from 'react-dom/client'
import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact, { BugsnagPluginReactResult } from '@bugsnag/plugin-react'
import './index.css'
import 'antd/dist/antd.css'
import './locales/i18n'
import App from './App'
import * as serviceWorkerRegistration from './serviceWorkerRegistration'
import reportWebVitals from './reportWebVitals'
import { onSuccess, onUpdate } from './registrationStatus'

Bugsnag.start({
  apiKey: process.env.REACT_APP_BUGSNAG_KEY || '',
  plugins: [new BugsnagPluginReact()],
})

const plugin = Bugsnag.getPlugin('react') as BugsnagPluginReactResult
const ErrorBoundary = plugin.createErrorBoundary(React) as any
const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <ErrorBoundary>
    <React.StrictMode>
      <Suspense fallback={<div>Loading... </div>}>
        <App />
      </Suspense>
    </React.StrictMode>
  </ErrorBoundary>
)

serviceWorkerRegistration.register({
  onUpdate,
  onSuccess,
})

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
