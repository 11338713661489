import { message, Modal } from 'antd'
import React, { ReactNode } from 'react'
import { AxiosError } from 'axios'
import { isEmptyObject } from './objectFns'

type ErrorType = {
  message: string
  code: string
  field: string
}

export const showMessage = (
  content: ReactNode,
  type: 'info' | 'success' | 'error' | 'warning' | 'loading' = 'info',
  duration = 2
) => {
  message.open({
    type,
    icon: <div />,
    content: content,
    duration,
  })
}

export const showMessageWithTitle = (
  title: string | ReactNode,
  content: ReactNode,
  okText = '확인',
  onOk?: () => void,
  cancelText?: string,
  onCancel?: () => void
) => {
  console.log('cancel text', cancelText)
  if (!!cancelText) {
    Modal.confirm({
      title,
      content,
      okText,
      onOk,
      centered: true,
      maskClosable: true,
      onCancel,
      cancelText,
    })
  } else {
    Modal.success({
      title,
      content,
      okText: <div className={' info'}>{okText}</div>,
      maskClosable: true,
      onCancel: onOk,
      onOk,
      centered: true,
    })
  }
}

export const showApiError = (error: any, onOk?: () => void, onCancel?: () => void) => {
  console.log('apiError', error.response)
  const errors: ErrorType[] = error.response?.data?.errors
  let popup: any = {}
  let fieldError: any = {}
  if (errors && errors.length > 0) {
    errors.forEach(e => {
      switch (e.field) {
        case 'toast':
          showMessage(e.message)
          return
        case 'ui.title':
          popup.title = e.message
          break
        case 'ui.text':
          popup.content = e.message
          break
        case 'ui.button.ok':
          popup.btnOk = e.message
          break
        case 'ui.button.cancel':
          popup.btnCancel = e.message
          break
        default:
          fieldError.field = e.field
          fieldError.message = e.message
      }
    })
    if (!isEmptyObject(popup)) {
      showMessageWithTitle(popup.title, popup.content, popup.btnOk, onOk, popup.btnCancel, onCancel)
      return
    } else if (!isEmptyObject(fieldError)) {
      showMessage(fieldError.field + fieldError.message)
    }
  } else {
    showMessage(error?.response?.data?.detail || error.message)
  }
}
