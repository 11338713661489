import { fetcher } from '../services/http.service'
import { GetListResponse, TOrderInfo } from './apiTypes'
import { queries } from './queries'
import { useEffect, useState } from 'react'
import useSWRInfinite from 'swr/infinite'

export function useOrders(tracking_number?: string) {
  const [orders, setOrders] = useState<TOrderInfo[]>([])
  const getKey = (pageIndex: number, previousPageData: GetListResponse<TOrderInfo>) => {
    if (!!tracking_number) {
      if (pageIndex === 0) return queries.shipping.orders(tracking_number)
      if (previousPageData && previousPageData.next) return previousPageData.next // reached the end
    }
    return null
  }

  const { data, isValidating, setSize } = useSWRInfinite<GetListResponse<TOrderInfo>>(getKey, fetcher, {
    revalidateIfStale: false,
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  })

  useEffect(() => {
    let listData: TOrderInfo[] = []
    if (data && data?.length > 0) {
      listData = []
      const lastRes = data[data?.length - 1]
      if (lastRes && lastRes.current_page * lastRes.items_per_page < lastRes.count) setSize(size => size + 1)
      else {
        data.forEach(item => {
          if (item?.results) {
            listData = listData.concat(item.results)
          }
        })
        setOrders(listData)
      }
    }
  }, [data])

  return {
    orders,
    isLoading: isValidating,
  }
}
