import React, { useRef } from 'react'
import styles from './pickupComponent.module.css'
import { useTranslation } from 'react-i18next'
import { Button, Space } from 'antd'
import { putDeliveryComplete } from '../../../api/shippingApi'
import { showMessage, showMessageWithTitle } from '../../../fns/message'
import { entryPasswordRegex, passwordRegex } from '../../../common/regex'
import EntranceForm from '../../organism/entrance/EntranceForm'
import { ECompleteMethod, EEntranceOption } from '../tracking/type'
import PickupLocationForm from '../../organism/pickupLocation/PickupLocationForm'
import { GetDeliveryInfoResponse, GetShippingInfoResponse } from '../../../api/apiTypes'

const PickupLocationRegister = ({
  shipping,
  info,
  tracking,
  onEntrySaved,
}: {
  shipping?: GetShippingInfoResponse
  info?: GetDeliveryInfoResponse
  tracking: string
  onEntrySaved: () => void
}) => {
  const { t } = useTranslation(['location'])
  const boxRef = useRef('')
  const boxPassRef = useRef('')
  const completeInfo = useRef('')
  const entranceCodeRef = useRef('')
  const entranceRef = useRef<EEntranceOption>()
  const completeMethodRef = useRef<ECompleteMethod>(ECompleteMethod.DOOR)

  const showSuccess = () => {
    if (shipping?.is_return)
      showMessageWithTitle(
        t('popup.success.entry.title.return'),
        t('popup.success.entry.content.return'),
        t('popup.success.entry.btn.ok')
      )
    else
      showMessageWithTitle(
        t('popup.success.entry.title'),
        t('popup.success.entry.content'),
        t('popup.success.entry.btn.ok')
      )

    onEntrySaved()
  }

  const handleChangeCompleteMethod = (value: ECompleteMethod) => {
    completeMethodRef.current = value
  }

  const handleChangeBox = (value: string) => {
    boxRef.current = value
  }

  const handleChangeBoxPass = (value: string) => {
    boxPassRef.current = value
  }

  const handleChangeEntrance = (value: EEntranceOption) => {
    entranceRef.current = value
  }

  const handleChangeCompletInfo = (value: string) => {
    completeInfo.current = value
  }

  const handleChangeEntranceCode = (value: string) => {
    entranceCodeRef.current = value
  }

  const handleSaveResult = () => {
    if (entranceRef.current === EEntranceOption.GATECODE) {
      //공동현관 비밀번호 선택시
      if (!entranceCodeRef.current) {
        showMessage(t('popup.error.entry.code.empty'))
        return
      } else if (!!entranceCodeRef.current && entryPasswordRegex.test(entranceCodeRef.current)) {
        showMessage(t('popup.error.code.invalid'))
        return
      }
    }
    if (completeMethodRef.current === ECompleteMethod.DELIVERYBOX) {
      //택배함 선택시
      if (!!boxPassRef.current && !passwordRegex.test(boxPassRef.current)) {
        showMessage(t('popup.error.code.invalid'))
        return
      }
    } else if (completeMethodRef.current === ECompleteMethod.OTHER) {
      if (!completeInfo.current) {
        showMessage(t('popup.error.other.empty'))
        return
      }
    }
    putDeliveryComplete(
      tracking,
      {
        entrance_option: entranceRef.current,
        entrance_password: entranceCodeRef.current,
        delivery_box_number: boxRef.current,
        delivery_box_password: boxPassRef.current,
        delivery_complete_on: completeMethodRef.current,
        delivery_complete_on_info: completeInfo.current,
      },
      showSuccess
    )
  }

  return (
    <Space direction={'vertical'} size={20} className={styles.inner}>
      <div className={styles.roundCntr + ' ' + styles.infoContainer}>
        <div className={'h2 primary preline'}>{t('pickup.location.info')}</div>
      </div>
      <PickupLocationForm
        info={info}
        onBoxNumChange={handleChangeBox}
        onBoxPasswordChange={handleChangeBoxPass}
        onCompleteMethodChange={handleChangeCompleteMethod}
        onCompleteInfoChange={handleChangeCompletInfo}
      />
      <EntranceForm
        info={info}
        onEntrancePasswordChange={handleChangeEntranceCode}
        onEntranceOptionChange={handleChangeEntrance}
      />
      <div style={{ height: 100 }} />
      <Button type={'primary'} className={styles.btnSave} data-cy={'saveResult'} onClick={handleSaveResult}>
        {t('location.btn.save')}
      </Button>
    </Space>
  )
}

export default PickupLocationRegister
