import React, { useEffect, useRef } from 'react'
import styles from './return.module.css'
import { Checkbox, Col } from 'antd'
import { TOrderInfo } from '../../../api/apiTypes'
import { useTranslation } from 'react-i18next'
import { TRequestReturnOrder } from '../../screens/tracking/type'
import { InputNumber } from '../../molecules/inputNumber/InputNumber'

interface Props extends TOrderInfo {
  defaultChecked: boolean
  onCountChanged: (checked: boolean, order: TRequestReturnOrder) => void
}

export const OrderItem = ({
  onCountChanged,
  order_item_number,
  quantity,
  options,
  product_name,
  defaultChecked,
}: Props) => {
  const { t } = useTranslation('return')
  const checkedRef = useRef(defaultChecked)
  const countRef = useRef(quantity)

  const handleCountChanged = (count: number) => {
    console.log('handleCountChanged', count)
    countRef.current = count
    onCountChanged(checkedRef.current, { order_item_number, product_name, quantity: countRef.current })
  }
  const handleCheckChanged = (e: any) => {
    console.log('handleCheckChanged', e.target.checked)
    checkedRef.current = e.target.checked
    onCountChanged(checkedRef.current, { order_item_number, product_name, quantity: countRef.current })
  }

  return (
    <div className={'column ' + styles.container}>
      <Checkbox onChange={handleCheckChanged} defaultChecked={defaultChecked}>
        <Col>
          <div className={'small1 bold ' + styles.order}>{product_name}</div>
          {!!options && <div className={'small1 ' + styles.option}>{t('option', { option: options })}</div>}
          <div className={'small1 ' + styles.option}>{t('quantity', { quantity })}</div>
        </Col>
      </Checkbox>
      <InputNumber
        className={styles.input}
        min={1}
        max={quantity}
        value={quantity}
        onChangeCount={handleCountChanged}
      />
    </div>
  )
}
