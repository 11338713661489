import React, { useEffect, useRef, memo } from 'react'
import { useTranslation } from 'react-i18next'
import { Col, Skeleton, Space } from 'antd'
import styles from './return.module.css'
import { TRequestReturnOrder } from '../../screens/tracking/type'
import { useOrders } from '../../../api/useOrders'
import { OrderItem } from './OrderItem'
import { GetDeliveryInfoResponse } from '../../../api/apiTypes'

type Props = {
  info?: GetDeliveryInfoResponse
  tracking_number?: string
  onUpdated: (orders: TRequestReturnOrder[]) => void
}

const defaultSelectAll = true
const OrderInfo = ({ tracking_number, onUpdated, info }: Props) => {
  const { t } = useTranslation(['return'])
  const { orders, isLoading } = useOrders(tracking_number)
  const ordersRef = useRef<TRequestReturnOrder[]>([])

  useEffect(() => {
    if (orders && defaultSelectAll) {
      ordersRef.current = orders
      onUpdated(orders)
    }
  }, [orders])

  const handleChangeCount = (checked: boolean, order: TRequestReturnOrder) => {
    ordersRef.current = ordersRef.current.filter(o => {
      return o.order_item_number !== order.order_item_number
    })
    if (checked) ordersRef.current.push(order)
    console.log('handleChangeCount3', ordersRef.current)
    onUpdated(ordersRef.current)
  }

  if (isLoading)
    return (
      <Col>
        <Skeleton title={false} paragraph={{ rows: 1, className: 'column' }} />
        <Col className={styles.orderContainer + ' ' + styles.container}>
          <Skeleton title={false} paragraph={{ rows: 3, className: 'column' }} />
        </Col>
      </Col>
    )

  return (
    <div className={'column'}>
      <span className={'body2 bold black85'}>
        {t('Order info', { order: orders?.length > 0 ? orders[0].order_number : '' })}
      </span>
      <Space className={styles.orderContainer} direction={'vertical'} size={1}>
        {orders &&
          orders.map(order => (
            <OrderItem
              key={order.order_item_number}
              {...order}
              defaultChecked={defaultSelectAll}
              onCountChanged={handleChangeCount}
            />
          ))}
      </Space>
    </div>
  )
}

export default memo(OrderInfo)
