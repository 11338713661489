import http from '../services/http.service'
import { showApiError } from '../fns/message'
import {
  IEntranceChangeParams,
  IEntranceCodeChangeParams,
  IRequestReturnParams,
} from '../components/screens/tracking/type'
import { queries } from './queries'

export const putDeliveryComplete = (
  tracking_number: string,
  {
    entrance_password,
    entrance_option,
    delivery_box_number,
    delivery_box_password,
    delivery_complete_on_info,
    delivery_complete_on,
    size,
    package_count,
    photo,
    save,
  }: IEntranceChangeParams,
  onSucceed: (data: any) => void
) => {
  console.log(
    'putDeliveryComplete',
    entrance_password,
    entrance_option,
    save,
    photo,
    delivery_complete_on,
    delivery_complete_on_info,
    delivery_box_password,
    delivery_box_number,
    size,
    package_count
  )
  const formData = new FormData()

  !!photo && formData.append('photo', photo, 'photo.png')
  !!size && formData.append('size', size)
  !!package_count && formData.append('package_count', package_count + '')
  !!delivery_complete_on && formData.append('delivery_complete_on', delivery_complete_on)
  !!delivery_complete_on_info && formData.append('delivery_complete_on_info', delivery_complete_on_info)
  !!entrance_option && formData.append('entrance_option', entrance_option)
  !!entrance_password && formData.append('entrance_password', entrance_password)
  !!delivery_box_number && formData.append('delivery_box_number', delivery_box_number)
  !!delivery_box_password && formData.append('delivery_box_password', delivery_box_password)
  !!delivery_box_password && formData.append('delivery_box_password', delivery_box_password)
  !!save && formData.append('save', 'true')

  http
    .post(`/tracking/shippingitems/${tracking_number}/deliveryinfo/`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    .then(res => {
      console.log('postDeliveryComplete', res)
      onSucceed(res.data)
    })
    .catch(showApiError)
}

export const postEntrance = (
  tracking_number: string,
  { information, entrance_option }: IEntranceCodeChangeParams,
  onSucceed: (data: any) => void
) => {
  console.log('postEntrance', information, entrance_option)
  const formData: IEntranceCodeChangeParams = {}

  if (entrance_option) formData.entrance_option = entrance_option
  if (information) formData.information = information

  http
    .post(`/tracking/shippingitems/${tracking_number}/address/deliveryinfo/`, formData)
    .then(res => {
      console.log('postDeliveryComplete', res)
      onSucceed(res.data)
    })
    .catch(showApiError)
}

export const postReturn = (
  tracking_number: string,
  { order_items, return_memo, return_reason, process_return }: IRequestReturnParams
) => {
  console.log('postReturn', order_items, return_memo, return_reason, process_return)
  return http.post(queries.shipping.return(tracking_number), {
    order_items,
    return_memo,
    return_reason,
    process_return,
  })
}

export const postReturnPhoto = (tracking_number: string, photo: File) => {
  console.log('postReturnPhoto', photo)
  const formData = new FormData()
  formData.append('photo', photo, photo.name)

  return http.post(queries.shipping.returnPhoto(tracking_number), formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
}
