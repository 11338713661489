import React, { useRef, useState } from 'react'
import styles from './return.module.css'
import { useTranslation } from 'react-i18next'
import { Button, Space } from 'antd'
import { postReturn, postReturnPhoto } from '../../../api/shippingApi'
import { showApiError, showMessage } from '../../../fns/message'
import { EReturnReason, TRequestReturnOrder } from '../tracking/type'
import { GetDeliveryInfoResponse } from '../../../api/apiTypes'
import OrderInfo from '../../organism/return/OrderInfo'
import { ReturnReason } from '../../organism/return/ReturnReason'
import ShippingPhoto from '../../molecules/photo/ShippingPhoto'

export const ReturnRequestScreen = ({
  info,
  tracking,
  onComplete,
}: {
  info?: GetDeliveryInfoResponse
  tracking: string
  onComplete: () => void
}) => {
  const { t } = useTranslation(['return'])
  const reasonRef = useRef<EReturnReason>(EReturnReason.QUALITY)
  const detailRef = useRef('')
  const photoRef = useRef<File>()
  const ordersRef = useRef<TRequestReturnOrder[]>()
  const [isLoading, setLoading] = useState(false)

  const handleComplete = (res: any) => {
    console.log('res', res)
    showMessage(t('Return application has been completed.'))
    onComplete()
    setLoading(false)
  }
  const handleSaveResult = () => {
    if (!isLoading) {
      if (!detailRef.current) {
        showMessage(t('Please enter details.'))
      } else if (!ordersRef.current || ordersRef.current?.length <= 0) {
        showMessage(t('Please select items.'))
      } else {
        setLoading(true)
        postReturn(tracking, {
          order_items: ordersRef.current,
          return_reason: reasonRef.current,
          return_memo: detailRef.current,
          process_return: true,
        })
          .then(res => {
            console.log('postReturn', res)
            if (photoRef.current) {
              postReturnPhoto(tracking, photoRef.current)
                .then(handleComplete)
                .catch(showApiError)
                .finally(() => {
                  setLoading(false)
                })
            } else {
              handleComplete(res)
              setLoading(false)
            }
          })
          .catch(error => {
            console.log('res error', error.response)
            showApiError(error)
            setLoading(false)
          })
      }
    }
  }

  return (
    <Space direction={'vertical'} size={20} className={styles.inner}>
      <OrderInfo info={info} tracking_number={tracking} onUpdated={orders => (ordersRef.current = orders)} />
      <ReturnReason
        defaultReason={reasonRef.current}
        onChangeReason={reason => (reasonRef.current = reason)}
        onChangeDetail={detail => (detailRef.current = detail)}
      />
      <div className={'column'}>
        <div className={'body2 bold black85 ' + styles.radioGrTitle}>{t('Return photo')}</div>
        <ShippingPhoto className={'round-cntr ' + styles.photo} onSelect={photo => (photoRef.current = photo)} />
      </div>
      <div style={{ height: 100 }} />
      <Button type={'primary'} loading={isLoading} onClick={handleSaveResult}>
        {t('Request to return')}
      </Button>
    </Space>
  )
}
