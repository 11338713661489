import i18n from 'i18next'
import Backend from 'i18next-xhr-backend'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import trackingKO from './ko/tracking.json'
import locationKO from './ko/location.json'
import loginKO from './ko/login.json'
import returnKO from './ko/return.json'
import trackingEn from './en/tracking.json'
import locationEn from './en/location.json'
import loginEn from './en/login.json'
const resources = {
  ko: {
    tracking: trackingKO,
    location: locationKO,
    login: loginKO,
    return: returnKO,
  },
  en: {
    tracking: trackingEn,
    location: locationEn,
    login: loginEn,
  },
}
i18n
  .use(Backend)
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    resources,
    ns: ['tracking'],
    fallbackLng: 'ko',
    lng: 'ko',
    keySeparator: false,
    interpolation: {
      escapeValue: false,
    },
    react: {
      useSuspense: false,
    },
  })

export default i18n
