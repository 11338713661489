import useSWR from 'swr'
import { fetcher } from '../services/http.service'
import { GetDeliveryInfoResponse } from './apiTypes'

export function useDeliveryInfo(tracking_number: string) {
  const { data, error, isLoading, mutate } = useSWR<GetDeliveryInfoResponse>(
    `tracking/shippingitems/${tracking_number}/deliveryinfo/`,
    fetcher,
    { revalidateIfStale: false, revalidateOnFocus: false, revalidateOnReconnect: false }
  )

  console.log('useDeliveryInfo', data)
  return {
    mutate,
    deliveryInfo: data,
    isLoading,
    isError: error,
  }
}
