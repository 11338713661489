import axios from 'axios'

const http = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    Accept: '*/*',
    'Content-Type': 'application/json',
  },
  timeout: 30000,
})

export default http
export const fetcher = (url: string) => http.get(url).then(res => res?.data)
