import React, { ChangeEvent, useState } from 'react'
import styles from './return.module.css'
import { useTranslation } from 'react-i18next'
import { Col, Radio, RadioChangeEvent } from 'antd'
import { EReturnReason } from '../../screens/tracking/type'
import TextArea from 'antd/es/input/TextArea'

type Props = {
  defaultReason: EReturnReason
  onChangeReason: (reason: EReturnReason) => void
  onChangeDetail: (detail: string) => void
}
export const ReturnReason = ({ defaultReason, onChangeReason, onChangeDetail }: Props) => {
  const { t } = useTranslation(['return'])
  const [reason, setReason] = useState<EReturnReason>(defaultReason)
  const [reasonDetail, setReasonDetail] = useState('')

  const handleChangeReason = (e: RadioChangeEvent) => {
    setReason(e.target.value)
    onChangeReason(e.target.value)
  }

  const handleChangeDetail = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setReasonDetail(e.target.value)
    onChangeDetail(e.target.value)
  }

  return (
    <Col>
      <div className={'body2 bold ' + styles.title}>{t('Return reason')}</div>
      <Radio.Group value={reason} className={'round-cntr column'} onChange={handleChangeReason}>
        <Radio value={EReturnReason.QUALITY} className={'body2 bold ' + styles.radio}>
          {t('Defective product')}
        </Radio>
        <Radio value={EReturnReason.NO_REASON} className={'body2 bold ' + styles.radio}>
          {t('Change mind')}
        </Radio>
        <Radio value={EReturnReason.DAMAGED} className={'body2 bold ' + styles.radio}>
          {t('Product damaged')}
        </Radio>
        <Radio value={EReturnReason.WRONG_TIEM} className={'body2 bold ' + styles.radio}>
          {t('Different product')}
        </Radio>
        <Radio value={EReturnReason.NEED_EXCHANGE} className={'body2 bold ' + styles.radio}>
          {t('Exchange product')}
        </Radio>
        <Col className={styles.radio}>
          <div className={'body2 bold'} data-cy={'radioGate'}>
            {t('Detailed reason')}
          </div>
          <TextArea
            value={reasonDetail}
            rows={4}
            maxLength={50}
            autoComplete={'off'}
            className={styles.inputReason + ' body'}
            placeholder={t('Type detailed reason within 50 characters')}
            onChange={handleChangeDetail}
          />
        </Col>
      </Radio.Group>
    </Col>
  )
}
