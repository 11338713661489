import { useState } from 'react'

const PhoneSvg = () => {
  const [color, setColor] = useState('black')

  const handleMouseLeave = () => {
    setColor('black')
  }

  const handleMouseEnter = () => {
    setColor('rgba(0,0,0,0.85)')
  }

  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      onMouseLeave={handleMouseLeave}
      onMouseEnter={handleMouseEnter}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.2969 3.49609L14.2168 1.41797C14.0701 1.27059 13.8957 1.15366 13.7036 1.07389C13.5115 0.994115 13.3056 0.953075 13.0977 0.953125C12.6738 0.953125 12.2754 1.11914 11.9766 1.41797L9.73828 3.65625C9.59091 3.80298 9.47397 3.97738 9.3942 4.16944C9.31443 4.3615 9.27339 4.56743 9.27344 4.77539C9.27344 5.19922 9.43945 5.59766 9.73828 5.89648L11.375 7.5332C10.9919 8.37766 10.4592 9.14587 9.80273 9.80078C9.14789 10.4589 8.37974 10.9935 7.53516 11.3789L5.89844 9.74219C5.75171 9.59481 5.5773 9.47788 5.38525 9.39811C5.19319 9.31833 4.98726 9.27729 4.7793 9.27734C4.35547 9.27734 3.95703 9.44336 3.6582 9.74219L1.41797 11.9785C1.27041 12.1255 1.15338 12.3002 1.0736 12.4926C0.993827 12.685 0.952883 12.8913 0.953126 13.0996C0.953126 13.5234 1.11914 13.9219 1.41797 14.2207L3.49414 16.2969C3.9707 16.7754 4.62891 17.0469 5.30469 17.0469C5.44727 17.0469 5.58399 17.0352 5.71875 17.0117C8.35156 16.5781 10.9629 15.1777 13.0703 13.0723C15.1758 10.9688 16.5742 8.35938 17.0137 5.71875C17.1465 4.91211 16.8789 4.08203 16.2969 3.49609Z"
        fill={color}
        fill-opacity="0.85"
      />
    </svg>
  )
}

export default PhoneSvg
