import { useState } from 'react'
const ArrowDown = ({
  rotate,
  color: colorProp = '#000000',
  width = 9,
  height = 6,
}: {
  rotate: number
  width?: number
  height?: number
  color?: string
}) => {
  const [color, setColor] = useState<string>(colorProp)

  const handleMouseLeave = () => {
    setColor(colorProp)
  }

  const handleMouseEnter = () => {
    setColor(colorProp + '85')
  }

  return (
    <svg
      onMouseLeave={handleMouseLeave}
      onMouseEnter={handleMouseEnter}
      width={width}
      height={height}
      viewBox="0 0 9 6"
      fill="none"
      style={{ transform: `rotate(${rotate}deg)` }}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.65179 0.142944H7.81473C7.75782 0.142944 7.70424 0.170846 7.67076 0.216605L4.5 4.58714L1.32924 0.216605C1.29576 0.170846 1.24219 0.142944 1.18527 0.142944H0.348216C0.275671 0.142944 0.233261 0.225534 0.275671 0.284686L4.21094 5.70991C4.3538 5.90634 4.64621 5.90634 4.78795 5.70991L8.72322 0.284686C8.76674 0.225534 8.72433 0.142944 8.65179 0.142944Z"
        fill={color}
        fill-opacity="0.45"
      />
    </svg>
  )
}

export default ArrowDown
