import React, { useEffect } from 'react'
import './notfound.style.css'
import { useTranslation } from 'react-i18next'
import BunnySvg from '../../../assets/svgs/bunnyEmpty.svg'
import { useHistory } from 'react-router-dom'

const NotfoundScreen = () => {
  const { t } = useTranslation(['tracking'])
  const history = useHistory()

  useEffect(() => {
    history.push('/404')
  }, [history])
  return (
    <div data-cy={'notFoundDiv'} className={'container notfound-cntr'}>
      <div className={'notfound-404 primary'}>404</div>
      <div className={'notfound-txt black45'}>{t('notfound')}</div>
      <img alt={'notfound'} src={BunnySvg} />
    </div>
  )
}

export default NotfoundScreen
