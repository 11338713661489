import React, { useMemo } from 'react'
import '../../screens/tracking/tracking.style.css'
import { useTranslation } from 'react-i18next'
import { format } from 'date-fns'
import { ShippingStatusType } from '../../../api/apiTypes'

interface Props extends ShippingStatusType {
  last: boolean
  photo?: string
}
const ShippingStatusItem = ({ last, timestamp, status, location_name, photo, context }: Props) => {
  const { t } = useTranslation(['tracking'])

  const { reason, other } = useMemo(() => {
    let reason = '',
      other = ''
    if (context) {
      const reasons = context.split(' - ')
      if (context.length > 0) reason = reasons[0]
      if (context.length > 1) other = reasons[1]
    }
    return { reason, other }
  }, [])

  const formattedDate = useMemo(() => {
    if (!timestamp) return ''
    try {
      return format(new Date(timestamp), 'MM.dd HH:mm:ss')
    } catch (error) {
      return ''
    }
  }, [timestamp])

  return (
    <div className={'row tracking-del'}>
      <div className={'tracking-del-date body bold black85'}>
        <div className={'small medium black45'}>{formattedDate}</div>
        <div className={'body medium black85'}>
          {' '}
          {t('status.info.' + status, {
            hub: !location_name || location_name === 'N/A' ? 'Hub' : location_name,
            unit: !location_name || location_name === 'N/A' ? 'Unit' : location_name,
            shop: !location_name || location_name === 'N/A' ? 'Shop' : location_name,
          })}
        </div>
        {status === 'DELIVERYINCOMPLETE' && (
          <div className={'body medium black85'}>{other?.length > 0 ? other : t(reason)}</div>
        )}
        {!!photo && status === 'DELIVERYCOMPLETE' && (
          <img alt={'deliveryimg'} src={photo} className={'tracking-del-img'} />
        )}
      </div>
      <div className={'tracking-del-sts body  ' + (last ? ' primary bold' : ' black45 medium')}>
        {t('history.' + status || '')}
      </div>
    </div>
  )
}

export default ShippingStatusItem
