import useSWR from 'swr'
import { fetcher } from '../services/http.service'
import { GetShippingInfoResponse } from './apiTypes'
import { useHistory } from 'react-router-dom'
import { useEffect } from 'react'

export function useShippingInfo(tracking_number: string) {
  const history = useHistory()
  const { data, error, isLoading, mutate } = useSWR<GetShippingInfoResponse>(
    `tracking/shippingitems/${tracking_number}/info/`,
    fetcher,
    { revalidateIfStale: false, revalidateOnFocus: false, revalidateOnReconnect: false }
  )

  useEffect(() => {
    if (error && error?.response?.status === 404) {
      history.replace('/404')
    }
  }, [error])

  console.log('useShippingInfo', data)
  return {
    shipping: data,
    isLoading,
    mutate,
    isError: error,
  }
}
