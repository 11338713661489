export const deleteObjEmptyValue = (obj: any) => {
  for (var propName in obj) {
    if (!obj[propName]) {
      delete obj[propName]
    }
  }
  return obj
}

export const isEmptyObject = (object: any) => {
  return !object || !(Object.keys(object).length > 0)
}

/**
 * Takes in an object and returns an array of strings
 * based on the object's key-value pairs.
 * @param {Object} o - The object
 * @return {string[]} Array of strings converted from object
 */
export function objectToStringArray(o: any): string[] {
  const keyValuePairs: string[] = []
  Object.keys(o).forEach(key => {
    if (Array.isArray(o[key])) {
      o[key].forEach((str: any) => {
        keyValuePairs.push(`${encodeURIComponent(key)}=${encodeURIComponent(str)}`)
      })
    } else {
      keyValuePairs.push(`${encodeURIComponent(key)}=${encodeURIComponent(o[key])}`)
    }
  })
  return keyValuePairs
}

/**
 * This function takes an object and converts it into a query string.
 *
 * @param {object} obj  An object with key value pairs.
 *
 * @returns {string} A query string.
 */
export function objToQueryString(obj?: any) {
  if (!obj) {
    return ''
  }
  let keyValuePairs: string[] = []
  if (Array.isArray(obj)) {
    obj.forEach(o => {
      keyValuePairs = [...keyValuePairs, ...objectToStringArray(o)]
    })
  } else {
    keyValuePairs = objectToStringArray(obj)
  }
  return keyValuePairs.join('&')
}
