import React, { useEffect, useState } from 'react'
import styles from './entrance.module.css'
import { Trans, useTranslation } from 'react-i18next'
import { Input, Radio } from 'antd'
import { EEntranceOption } from '../../screens/tracking/type'
import { GetDeliveryInfoResponse } from '../../../api/apiTypes'

type Props = {
  info?: GetDeliveryInfoResponse
  entranceCode?: string
  onEntranceOptionChange: (entranceOption: EEntranceOption) => void
  onEntrancePasswordChange: (entrancePassword: string) => void
}
const EntranceForm = ({ info, onEntranceOptionChange, onEntrancePasswordChange }: Props) => {
  const { t } = useTranslation(['location'])
  const [entrance, setEntrance] = useState<EEntranceOption>(info?.entrance_option || EEntranceOption.FREE)
  const [entranceCode, setEntranceCode] = useState(info?.entrance_password)

  useEffect(() => {
    if (info) {
      if (info.entrance_option && info.entrance_option in EEntranceOption) {
        setEntrance(info?.entrance_option)
      }
      if (info.entrance_password) {
        setEntranceCode(info?.entrance_password)
      }
    }
  }, [info])

  useEffect(() => {
    onEntranceOptionChange(entrance)
  }, [entrance])

  useEffect(() => {
    entranceCode && onEntrancePasswordChange(entranceCode)
  }, [entranceCode])

  return (
    <div className={'column'} style={{ width: '100%' }}>
      <div className={'body2 bold ' + styles.radioGrTitle}>{t('loc.entry.title')}</div>
      <div className={'round-cntr column'}>
        <div className={'delivery-method-inner'}>
          <Radio.Group className={'column'} onChange={e => setEntrance(e.target.value)} value={entrance}>
            <Radio value={EEntranceOption.FREE} data-cy={'radioFree'} className={'body2 bold ' + styles.trackingOption}>
              {t('FREE')}
            </Radio>

            <div className={styles.trackingOption}>
              <div className={styles.radioCol}>
                <Radio className={'body2 bold'} data-cy={'radioGate'} value={EEntranceOption.GATECODE}>
                  <Trans i18nKey={'GATECODE'} ns={'location'} components={{ blue: <span className={'primary'} /> }} />
                </Radio>
                {entrance === EEntranceOption.GATECODE && (
                  <Input
                    value={entranceCode}
                    maxLength={20}
                    autoComplete={'off'}
                    className={styles.input}
                    data-cy={'changeCode'}
                    placeholder={t('loc.entry.code.place')}
                    onChange={e => setEntranceCode(e.target.value)}
                  />
                )}
              </div>
            </div>

            <Radio
              value={EEntranceOption.GUARD}
              data-cy={'radioGUARD'}
              className={'body2 bold ' + styles.trackingOption}
            >
              {t('GUARD')}
            </Radio>
          </Radio.Group>
        </div>
      </div>
    </div>
  )
}

export default EntranceForm
