import React from 'react'
import styles from './deliveryHistoryPhotos.module.css'
import close from '../../../assets/svgs/close.svg'
import { useTranslation } from 'react-i18next'

type Props = {
  visible: boolean
  onBack: () => void
  previewImage?: string
}
const DeliveryHistoryPhotos = ({ visible, onBack, previewImage }: Props) => {
  const { t } = useTranslation(['delivery'])
  console.log('preview', previewImage)
  if (!visible) return null

  return (
    <div className={styles.modal}>
      <div className={styles.closeCntr} onClick={onBack}>
        <img alt={'close'} src={close} className={styles.close} />
      </div>
      <div className={styles.imgCntr}>
        {!!previewImage ? (
          <img alt={'deliveryPhoto'} className={styles.fullImage} src={previewImage} />
        ) : (
          <div className={'body bold white ' + styles.empty}>{t('history.empty')}</div>
        )}
      </div>
    </div>
  )
}

export default DeliveryHistoryPhotos
